import React, { useState, useEffect } from 'react';
import { Box, Card, Grid, LinearProgress, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import Button from '@mui/material/Button';
import Header from "../header/Header";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";
import SubTopHead from "../sub-top-head/SubTopHead";
import VideoThumbnail from 'react-video-thumbnail';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';

import "../../App.css";
import "../subject/Subject.css";
import "../private-class/PrivateClass.css";
import "../overview/Overview.css";
import "../responsive/Responsive.css";

import clock from '../../assets/images/clock.svg';
import calendar from '../../assets/images/calendar.svg';
import studentGraph from '../../assets/images/studentGraph.svg';
import sp1 from '../../assets/images/sp1.svg';
import attendance from '../../assets/images/attendance.svg';
import problemSolving from '../../assets/images/problemSolving.svg';
import pie1 from '../../assets/images/pie1.svg';
import pie2 from '../../assets/images/pie2.svg';
import teachinfopic from '../../assets/images/techPic1.png';
import advertising1 from '../../assets/images/Addlogo.png';
import advertising2 from '../../assets/images/play-store.png';
import advertising3 from '../../assets/images/apple-store.png';
import advertising4 from '../../assets/images/artboard.png';
import studyMaterial from '../../assets/images/studyMaterial.png';
import watchRecording from '../../assets/images/watchRecording.png';
import classNotes from '../../assets/images/classNotes.png';
import assignmentImg from '../../assets/images/assignment_img.png';
import download from '../../assets/images/gray_download.svg';
import pdfRed from '../../assets/images/pdf-red.svg';
import pdfImg from '../../assets/images/img_blue.svg';
import msgUser1 from '../../assets/images/msgUser1.png';
import chat_img from '../../assets/images/chat_img.png';
import sendAero from '../../assets/images/send_Aero.svg';
import attachFile from '../../assets/images/attach_file.svg';
import courseListImg from '../../assets/images/courseListuser.png';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Assignment from './Assignment';
import Chat, { ZoomImageContainer } from './Chat';
import VideoModal from '../common-components/VideoModal';
import Advertisement from '../common-components/Advertisement';

import useTranslations from '../../custom-hooks/useTranslations';
import useClasses from '../../custom-hooks/useClasses';
import { getClasses } from '../../actions/classesAction';
import { getParticipanetsList } from '../../actions/getParticipanetsListAction';
import { getChatBackup } from '../../actions/getChatBackupAction';
import { privateClassesAssignment } from '../../actions/privateClassesAssignment';

import { encryptData } from '../../services/encryptDecrypt';
import generateAgoraToken from '../../services/AgoraTokenService';

import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CircularProgressWithLabel(props) {
    return (
        <Box className="custom-progress" sx={{ position: 'relative', display: 'inline-flex', backgroundColor: '#fff', borderRadius: '50%' }}>
            <CircularProgress variant="determinate" sx={{ color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8') }} {...props} thickness={6} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

const Overview = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { classId, tab } = useParams();
    const translations = useTranslations();
    const { getClass } = useClasses();
    const session = getClass(atob(classId));
    const [isVideoModal, setIsVideoModal] = useState(false);
    const [selectedClass, setSelectedClass] = useState({});

    const [value, setValue] = useState(tab ? Number(atob(tab)) : 0);

    const translation = {
        ...translations[8]?.PrivateClassDashboard,
        ...translations[12]?.ClassDetail,
        ...translations[13]?.Payment,
        ...translations[21]?.CommonFrontend,
        ...translations[23]?.TutorRegistration,
        ...translations[26]?.QuickCall,
        ...translations[31]?.Wallet,
        ...translations[36]?.PracticeTest,
    }

    const profileInfo = Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")): null;


    const handleBack = () => {
        navigate(-1);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const viewRecordedVideo = (cls) => {
        setIsVideoModal(true);
        setSelectedClass(cls);
    }

    const handleClose = () => {
        setIsVideoModal(false);
    }

    const getSessionStatusPercentage = () => {

        const totalSessions = session?.ClassSessions?.length;
        const completeSessions = session?.ClassSessions?.filter(
            (session) => session.SessionStatus === "complete"
        )?.length;

        const percentageComplete = (completeSessions / totalSessions) * 100;
        return percentageComplete
    };

    const joinSession = (cls) => {
        const tutorResponse = {
            TutorID: session?.TutorID,
            TutorName: session?.TutorName,
            TutorImage: session?.ImageURL,
            StudentID: profileInfo?.ID,
            StudentName: profileInfo?.UserName,
            StudentImage: profileInfo?.ProfilePictureUrl
        }
        sessionStorage.setItem('pathName', window.location.pathname);
        sessionStorage.setItem("TutorResponse", JSON.stringify(tutorResponse));
        generateAgoraToken(profileInfo?.UID, 2, `channel${cls?.ID}${session?.ID}`).then((res) => {
            localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
            localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
            navigate(`/classroom/${encryptData(Number(session.ID))}/${encryptData(cls.ID)}`);
        });
    };

    useEffect(() => {
        dispatch(getClasses());
        dispatch(getParticipanetsList({ ClassID: atob(classId) }));
        dispatch(getChatBackup({ClassID: 'PC' + atob(classId)}));
        dispatch(privateClassesAssignment({ ClassID: atob(classId) }))
    }, [])

    return (
        <div>
            <Header />
            <Box className="quikCallBanner">
                <Box className='OuterContainer' sx={{ border: 'none', borderRadius: '0px' }}>
                    <Typography variant='h1'>
                        {translation?.Hi} {profileInfo?.UserName}, <Typography variant='div'> {translation?.WelcomeYasa}</Typography>
                    </Typography>
                </Box>
            </Box>
            <Box className='OuterContainer containerShape' sx={{ background: '#EAF4FF', padding: '30px' }}>
                <Box className='SectionIn'>
                    <Box className="BackBtn">
                        <Button className='backBtn' variant="text" sx={{ mb: 2 }} startIcon={localStorage.getItem("I18N_LANGUAGE") === "en" ? <ArrowBackIcon /> : <ArrowForward />} onClick={handleBack}>{translation?.BackBtn}</Button>
                    </Box>
                    <Box sx={{ width: '100%' }} className="tab-wrapper">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label={translation?.Overview} {...a11yProps(0)} />
                                <Tab label={translation?.Schedule} {...a11yProps(1)} />
                                <Tab label={translation?.Documents} {...a11yProps(2)} />
                                <Tab label={translation?.Chats} {...a11yProps(3)} />
                                <Tab label={translation?.Payments} {...a11yProps(4)} />
                                <Tab label={translation?.Assignment} {...a11yProps(5)} />
                            </Tabs>
                        </Box>
                        <Box className="custom-panel-wrapper">
                            <CustomTabPanel value={value} index={0}>
                                <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: '600', color: '#000', fontFamily: '"Rajdhani", sans-serif;', marginBottom: '8px' }}>{session?.PrivateClassName}</Typography>
                                <Box className="oc_headwrap" sx={{ padding: '0', display: 'flex', gap: '10px' }}>
                                    <Box className="oc_headleft">
                                        <List sx={{ padding: '0', display: 'flex', gap: '15px', width: '100%' }}>
                                            <ListItem><img src={clock}></img>
                                                {new Date(session?.ClassesTimmings).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}                                            </ListItem>
                                            <ListItem><img src={calendar}></img>{new Date(session?.ClassesTimmings).toLocaleDateString('en-US')}</ListItem>
                                        </List>
                                    </Box>
                                </Box>
                                <Box className="performance-box">
                                    {/* <Typography variant='h5' sx={{ fontSize: '18px', display: 'flex', alignItems: 'center', gap: '8px' }}><img src={studentGraph} />{translation?.StudentPerformance}</Typography> */}
                                    <Box className="p-pbox">
                                        <Box className="p1-pbox">
                                            <Typography variant='h2' sx={{ fontSize: '32px', fontWeight: '600', color: '#555555', display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '12px' }}><img src={sp1} />{session?.StudentPerformance}</Typography>
                                            <Typography variant='h4' sx={{ fontSize: '20px', fontWeight: '600', color: '#555555', textTransform: 'capitalize' }}>{translation?.StudentPerformance}</Typography>
                                        </Box>
                                        <Box className="p2-pbox">
                                            <Box className="p2-pbox-inner">
                                                <Box className="p2-child-inner">
                                                    <img src={attendance} />
                                                    <Typography variant='h4' sx={{ fontSize: '20px', fontWeight: '600', color: '#555555' }}>{translation?.Attendance}</Typography>
                                                </Box>
                                                {/* <img src={pie1} /> */}
                                                <CircularProgressWithLabel value={getSessionStatusPercentage()} />
                                            </Box>
                                            <Box className="p2-pbox-inner">
                                                <Box className="p2-child-inner">
                                                    <Typography variant='h4' sx={{ fontSize: '20px', fontWeight: '600', color: '#555555' }}>{translation?.ProblemSolving}</Typography>
                                                </Box>
                                                {/* <img src={pie2} /> */}
                                                <CircularProgressWithLabel value={ session?.NumberOfSolvedProblem && session?.NumberOfProblem
                                                ? (session.NumberOfSolvedProblem / session.NumberOfProblem) * 100
                                                : 0} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="rank_msg_wrap">
                                    <Box className="RankInfo" sx={{ gap: 1.5, display: { xs: 'flex', alignItems: 'top' } }}>
                                        <Box
                                            className={`ProfilePic ${session?.OnlineStatusID === '1' ? 'add-online' : session?.OnlineStatusID === '2' ? 'add-busy' : 'add-offline' }`} 
                                            onClick={() => navigate(`/tutor-profile/${btoa(session.TutorID)}`)}>
                                            <img src={session?.ImageURL}></img>
                                        </Box>
                                        <Box className="TeachInfo">
                                            <Typography variant='h5'>{session?.TutorName}</Typography>
                                            <Box className="RatingBox">
                                                <Stack spacing={2}>
                                                    <Rating sx={{direction: 'ltr'}} name="half-rating-read" value={parseFloat(session?.TutorRating)} precision={0.5} readOnly />
                                                </Stack>
                                            </Box>
                                        </Box>

                                    </Box>
                                    <Button className='darkBlue' onClick={() => setValue(3)}>{translation?.Message}</Button>
                                </Box>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                {/* <Box className="white-card " sx={{marginBottom: '16px'}}>
                                    <Box className="dflx">
                                        <Box className="left_sect">
                                            <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: '600', color: '#000', fontFamily: '"Rajdhani", sans-serif;', marginBottom: '8px', color: '#005DB8' }}>{session?.PrivateClassName}</Typography>
                                            <Box className="oc_headwrap" sx={{ padding: '0', display: 'flex', gap: '10px', marginBottom: '0 !important' }}>
                                                <Box className="oc_headleft">
                                                    <List sx={{ padding: '0', display: 'flex', gap: '15px', width: '100%' }}>
                                                        <ListItem><img src={clock}></img> {session?.ClassesTimmings}</ListItem>
                                                        <ListItem><img src={calendar}></img> {new Date(session?.ClassesDate).toLocaleDateString('en-US')}</ListItem>
                                                    </List>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="right_sect">
                                            <Box className="lbl_btn_flx">
                                                <Typography variant="span" className="lbl_tag lt_yellow">ongoing</Typography>
                                                <Button sx={{ fontSize: '14px', color: '#fff', backgroundColor: '#005DB8', minWidth: '80px', height: '28px', textTransform: 'capitalize', borderRadius: '4px', '&:hover': { backgroundColor: 'transparent', border: '1px solid #005DB8', color: '#005DB8' } }}>join now</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box> */}
                                {/* <Box className="white-card" sx={{marginBottom: '16px'}}>
                                    <Box className="dflx">
                                        <Box className="left_sect">
                                            <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: '600', color: '#000', fontFamily: '"Rajdhani", sans-serif;', marginBottom: '8px', color: '#005DB8' }}>Session:-01 Obtain the greatest number of your digits</Typography>
                                            <Box className="oc_headwrap" sx={{ padding: '0', display: 'flex', gap: '10px', marginBottom: '0 !important' }}>
                                                <Box className="oc_headleft">
                                                    <List sx={{ padding: '0', display: 'flex', gap: '15px', width: '100%' }}>
                                                        <ListItem><img src={clock}></img> 06:00 pm to 07:00 pm</ListItem>
                                                        <ListItem><img src={calendar}></img> 17 Oct 2023</ListItem>
                                                    </List>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="right_sect">
                                            <Box className="lbl_btn_flx">
                                                <Typography variant="span" className="lbl_tag lt_purple">schedule</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box> */}

                                { session?.ClassSessions?.map((cls, index) => {
                                    return <Box className="white-card" sx={{marginBottom: '16px'}} key={cls?.ID}>
                                        <Box className="dflx">
                                            <Box className="left_sect">
                                                <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: '600', color: '#000', fontFamily: '"Rajdhani", sans-serif;', marginBottom: '8px', color: '#005DB8' }}>{`${translation?.Session} ${index + 1} : ${cls?.SessionName}`}</Typography>
                                                <Box className="oc_headwrap" sx={{ padding: '0', display: 'flex', gap: '10px', marginBottom: '0 !important' }}>
                                                    <Box className="oc_headleft">
                                                        <List sx={{ padding: '0', display: 'flex', gap: '15px', width: '100%' }}>
                                                            <ListItem><img src={clock}></img>     {new Date(cls?.ClassesTimmings).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
                                                            </ListItem>
                                                            <ListItem><img src={calendar}></img> {new Date(cls?.ClassesTimmings).toLocaleDateString('en-US')}</ListItem>
                                                        </List>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box className="right_sect">
                        
                                                <Box className="lbl_btn_flx">
                                                    { cls?.SessionStatus === 'Complete' &&
                                                        <Typography variant="span" className="lbl_tag lt-green">{translation?.CompletedSession}</Typography>
                                                    }
                                                    { session?.ClassStatus === 'Active' && cls?.SessionStatus === 'Schedule' &&
                                                        <Typography variant="span" className="lbl_tag lt_purple">{translation?.Schedule}</Typography>
                                                    }
                                                    { cls?.SessionStatus === 'Cancelled' &&
                                                        <Typography variant="span" className="lbl_tag lt_red">{translation?.Cancelled}</Typography>
                                                    }
                                                    { session?.ClassStatus === 'Active' && cls.SessionStatus === 'On Going' && <>
                                                        <Typography variant="span" className="lbl_tag lt_yellow">{translation?.OnGoing}</Typography>
                                                        <Button sx={{ fontSize: '14px', color: '#fff', backgroundColor: '#005DB8', minWidth: '80px', height: '28px', textTransform: 'capitalize', borderRadius: '4px', '&:hover': { backgroundColor: 'transparent', border: '1px solid #005DB8', color: '#005DB8' } }} onClick={() => joinSession(cls)}>{translation?.JoinSession}</Button>
                                                    </> }
                                                </Box>
                                            </Box>
                                        </Box>
                                        
                                        { cls?.SessionStatus === 'Complete' && 
                                            <Box sx={{ marginTop: '24px' }}>
                                                <Box className="boxes-wrapper">
                                                    <Box className="box_wrp" sx={{ cursor: 'pointer' }} onClick={() => setValue(2)}>
                                                        <img src={studyMaterial} sx={{ marginBotton: '8px' }} />
                                                        <Typography variant="h4" sx={{ fontSize: '20px', fontSize: '18px', fontWeight: '500', color: '#000' }}>{translation?.StudyMaterial}</Typography>
                                                    </Box>
                                                    <Box className="box_wrp" sx={{ cursor: 'pointer' }} onClick={() => viewRecordedVideo(cls)}>
                                                        <img src={watchRecording} sx={{ marginBotton: '8px' }} />
                                                        <Typography variant="h4" sx={{ fontSize: '20px', fontSize: '18px', fontWeight: '500', color: '#000' }}>{translation?.WatchRecording}</Typography>
                                                    </Box>
                                                    <Box className="box_wrp" sx={{ cursor: 'pointer' }} onClick={() => window.open(cls?.SessionNotesUrl, '_blank')}>
                                                        <img src={classNotes} sx={{ marginBotton: '8px' }} />
                                                        <Typography variant="h4" sx={{ fontSize: '20px', fontSize: '18px', fontWeight: '500', color: '#000' }}>{translation?.ClassNote}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="ass_box">
                                                    <Box className="ass_left">
                                                        <img src={assignmentImg} />
                                                        <Typography variant="h4" sx={{ fontSize: '18px', color: '#000000', textTransform: 'capitalize' }}>{translation?.Assignment}</Typography>
                                                        <Typography variant="h6" sx={{ fontSize: '14px', color: '#EA4435', marginLeft: '8px', fontWeight: '600' }}>{cls?.LeftDuration} {translation?.Hour} {translation?.Left}</Typography>
                                                    </Box>
                                                    <Box className="ass_right">
                                                        <Box className="ps_box">
                                                            <Typography variant='span' sx={{ fontSize: '14px', fontWeight: '600', }}>{cls?.NumberOfSolvedProblem}/{cls?.NumberOfProblem} {translation?.ProblemSolved}</Typography>
                                                        </Box>
                                                        <Button sx={{
                                                            color: '#ffffff', border: '1px solid #005DB8', background: '#005DB8', padding: '8px 16px', minWidth: '105px', borderRadius: '4px', height: '34px', '&:hover': {
                                                                backgroundColor: "transparent", color: '#005DB8' 
                                                            },
                                                        }} onClick={() => setValue(5)}>{translation?.StartSolving}</Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                }) }


                                {/* <Box className="white-card" sx={{marginBottom: '16px'}}>
                                    <Box className="dflx">
                                        <Box className="left_sect">
                                            <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: '600', color: '#000', fontFamily: '"Rajdhani", sans-serif;', marginBottom: '8px', color: '#005DB8' }}>Session:-02 Obtain the greatest number of your digits</Typography>
                                            <Box className="oc_headwrap" sx={{ padding: '0', display: 'flex', gap: '10px', marginBottom: '0 !important' }}>
                                                <Box className="oc_headleft">
                                                    <List sx={{ padding: '0', display: 'flex', gap: '15px', width: '100%' }}>
                                                        <ListItem><img src={clock}></img> 06:00 pm to 07:00 pm</ListItem>
                                                        <ListItem><img src={calendar}></img> 17 Oct 2023</ListItem>
                                                    </List>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="right_sect">
                                            <Box className="lbl_btn_flx">
                                                <Typography variant="span" className="lbl_tag lt-green">Completed</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginTop: '24px' }}>
                                        <Box className="boxes-wrapper">
                                            <Box className="box_wrp">
                                                <img src={studyMaterial} sx={{ marginBotton: '8px' }} />
                                                <Typography variant="h4" sx={{ fontSize: '20px', fontSize: '18px', fontWeight: '500', color: '#000' }}>study material</Typography>
                                            </Box>
                                            <Box className="box_wrp">
                                                <img src={watchRecording} sx={{ marginBotton: '8px' }} />
                                                <Typography variant="h4" sx={{ fontSize: '20px', }}>watch recording</Typography>
                                            </Box>
                                            <Box className="box_wrp">
                                                <img src={classNotes} sx={{ marginBotton: '8px' }} />
                                                <Typography variant="h4" sx={{ fontSize: '20px', }}>class notes</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="ass_box">
                                            <Box className="ass_left">
                                                <img src={assignmentImg} />
                                                <Typography variant="h4" sx={{ fontSize: '18px', color: '#000000', textTransform: 'capitalize' }}>assignment</Typography>
                                                <Typography variant="h6" sx={{ fontSize: '14px', color: '#EA4435', marginLeft: '8px', fontWeight: '600' }}>04 hours left</Typography>
                                            </Box>
                                            <Box className="ass_right">
                                                <Box className="ps_box">

                                                    <Typography variant='span' sx={{ fontSize: '14px', fontWeight: '600', }}>0/6 problem solved</Typography>
                                                </Box>
                                                <Button sx={{ color: '#005DB8', border: '1px solid #005DB8', padding: '8px 16px', minWidth: '105px', borderRadius: '4px', height: '34px' }}>Start Solving</Button>
                                            </Box>
                                        </Box>
                                    </Box>


                                </Box>
                                <Box className="white-card" sx={{marginBottom: '16px'}}>
                                    <Box className="dflx">
                                        <Box className="left_sect">
                                            <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: '600', color: '#000', fontFamily: '"Rajdhani", sans-serif;', marginBottom: '8px', color: '#005DB8' }}>Session:-02 Obtain the greatest number of your digits</Typography>
                                            <Box className="oc_headwrap" sx={{ padding: '0', display: 'flex', gap: '10px', marginBottom: '0 !important' }}>
                                                <Box className="oc_headleft">
                                                    <List sx={{ padding: '0', display: 'flex', gap: '15px', width: '100%' }}>
                                                        <ListItem><img src={clock}></img> 06:00 pm to 07:00 pm</ListItem>
                                                        <ListItem><img src={calendar}></img> 17 Oct 2023</ListItem>
                                                    </List>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="right_sect">
                                            <Box className="lbl_btn_flx">
                                                <Typography variant="span" className="lbl_tag lt-green">Completed</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginTop: '24px' }}>
                                        <Box className="boxes-wrapper">
                                            <Box className="box_wrp">
                                                <img src={studyMaterial} sx={{ marginBotton: '8px' }} />
                                                <Typography variant="h4" sx={{ fontSize: '20px', fontSize: '18px', fontWeight: '500', color: '#000' }}>study material</Typography>
                                            </Box>
                                            <Box className="box_wrp">
                                                <img src={watchRecording} sx={{ marginBotton: '8px' }} />
                                                <Typography variant="h4" sx={{ fontSize: '20px', }}>watch recording</Typography>
                                            </Box>
                                            <Box className="box_wrp">
                                                <img src={classNotes} sx={{ marginBotton: '8px' }} />
                                                <Typography variant="h4" sx={{ fontSize: '20px', }}>class notes</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="ass_box">
                                            <Box className="ass_left">
                                                <img src={assignmentImg} />
                                                <Typography variant="h4" sx={{ fontSize: '18px', color: '#000000', textTransform: 'capitalize' }}>assignment</Typography>
                                                <Typography variant="h6" sx={{ fontSize: '14px', color: '#EA4435', marginLeft: '8px', fontWeight: '600' }}>04 hours left</Typography>
                                            </Box>
                                            <Box className="ass_right">
                                                <Box className="ps_box">

                                                    <Typography variant='span' sx={{ fontSize: '14px', fontWeight: '600', }}>0/6 problem solved</Typography>
                                                </Box>
                                                <Button sx={{ color: '#005DB8', border: '1px solid #005DB8', padding: '8px 16px', minWidth: '105px', borderRadius: '4px', height: '34px' }}>Start Solving</Button>
                                            </Box>
                                        </Box>
                                    </Box>


                                </Box>
                                <Box className="white-card" sx={{marginBottom: '16px'}}>
                                    <Box className="dflx">
                                        <Box className="left_sect">
                                            <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: '600', color: '#000', fontFamily: '"Rajdhani", sans-serif;', marginBottom: '8px', color: '#005DB8' }}>Session:-01 Obtain the greatest number of your digits</Typography>
                                            <Box className="oc_headwrap" sx={{ padding: '0', display: 'flex', gap: '10px', marginBottom: '0 !important' }}>
                                                <Box className="oc_headleft">
                                                    <List sx={{ padding: '0', display: 'flex', gap: '15px', width: '100%' }}>
                                                        <ListItem><img src={clock}></img> 06:00 pm to 07:00 pm</ListItem>
                                                        <ListItem><img src={calendar}></img> 17 Oct 2023</ListItem>
                                                    </List>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="right_sect">
                                            <Box className="lbl_btn_flx">
                                                <Typography variant="span" className="lbl_tag lt_red">reschedule / canceled</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box> */}
                                {/* <Box className="viewMore" sx={{ mt: 3, mb: 3, gap: 0.5, display: { xs: 'flex', alignItems: 'center', justifyContent: 'center' } }}>
                                    <button className='viewMoreBtn'>view more (05)</button>
                                </Box> */}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                { session?.Documents?.map((note) => {
                                    return <Box className="white-card document_wrap" key={note?.ID}>
                                        <Box className="left_doc">
                                            <Box className="docImgSample">
                                                <Box className="docImg_wrap">
                                                    {/* <img src={pdfRed} /> */}
                                                    {/* <img src={pdfImg} /> */}
                                                    <img src={note?.ImageURL} />
                                                </Box>
                                                <Box>
                                                    <Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#000000' }}>
                                                        {`${translation?.Session} ${session.ClassSessions?.findIndex((clsSession) => clsSession?.ID === note?.SessionID) + 1} : ${note?.Value}`}
                                                    </Typography>
                                                    <Box className="doc_time">
                                                        <Box>
                                                            <img src={clock} />
                                                            <Typography>{new Date(note?.DateTime).toLocaleTimeString()}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <img src={calendar} />
                                                            <Typography>{new Date(note?.DateTime).toLocaleDateString('en-US')}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="right_doc" onClick={() => window.open(note?.DocumentURL, '_blank')}>
                                            <img src={download} />
                                        </Box>
                                    </Box>
                                }) }
                                {/* <Box className="white-card document_wrap">
                                    <Box className="left_doc">
                                        <Box className="docImgSample">
                                            <img src={pdfRed} />
                                            <img src={pdfImg} />
                                            <Box>
                                                <Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#000000' }}>sample pdf documents</Typography>
                                                <Box className="doc_time">
                                                    <Box>
                                                        <img src={clock} />
                                                        <Typography>6:00 Pm</Typography>
                                                    </Box>
                                                    <Box>
                                                        <img src={calendar} />
                                                        <Typography>17 Oct 2023</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className="right_doc">
                                        <img src={download} />
                                    </Box>
                                </Box>
                                <Box className="white-card document_wrap">
                                    <Box className="left_doc">
                                        <Box className="docImgSample">
                                            <img src={pdfRed} />
                                            <img src={pdfImg} />
                                            <Box>
                                                <Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#000000' }}>sample pdf documents</Typography>
                                                <Box className="doc_time">
                                                    <Box>
                                                        <img src={clock} />
                                                        <Typography>6:00 Pm</Typography>
                                                    </Box>
                                                    <Box>
                                                        <img src={calendar} />
                                                        <Typography>17 Oct 2023</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className="right_doc">
                                        <img src={download} />
                                    </Box>
                                </Box> */}
                                {/* <Box className="viewMore" sx={{ mt: 3, mb: 3, gap: 0.5, display: { xs: 'flex', alignItems: 'center', justifyContent: 'center' } }}>
                                    <button className='viewMoreBtn'>view more (05)</button>
                                </Box> */}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={3}>
                                <Chat />

                                {/* <Box className="chat_wrapper_parent">
                                    <Box className="chat_wrapper">
                                        <Box className="chat_left">
                                            <Typography sx={{ color: '005DB8', marginBottom: '16px', fontSize: '18px', fontWeight: '600', color: '#727272' }}>chats(2)</Typography>
                                            <Paper
                                                component="form"
                                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', marginBottom: '16px', height: '40px', boxShadow: 'none', borderRadius: '8px' }}
                                            > <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                                    <SearchIcon />
                                                </IconButton>
                                                <InputBase
                                                    sx={{ ml: 1, flex: 1 }}
                                                    placeholder="Search Here"
                                                    inputProps={{ 'aria-label': 'Search Here' }}
                                                />

                                            </Paper>
                                            <Box className="read_label">
                                                <Typography variant='span' className='active'>all</Typography>
                                                <Typography variant='span'>unread</Typography>
                                            </Box>
                                            <Box className="msg_box_wrapper">
                                                <Box className="msg_box">
                                                    <img src={msgUser1} />
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#000000', textTransform: 'capitalize', marginBottom: '3px', lineHeight: '1' }}>arlene</Typography>
                                                        <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#555555', textTransform: 'capitalize', lineHeight: '1' }}>can yu explain me again ?</Typography>
                                                    </Box>
                                                    <Typography variant='span' sx={{ minWidth: '20px', width: '20px', height: '20px', backgroundColor: '#EAF4FF', color: '#005DB8', borderRadius: '50%', fontSize: '14px', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px' }}>1</Typography>
                                                </Box>
                                                <Box className="msg_box">
                                                    <img src={msgUser1} />
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#000000', textTransform: 'capitalize', marginBottom: '3px', lineHeight: '1' }}>arlene</Typography>
                                                        <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#555555', textTransform: 'capitalize', lineHeight: '1' }}>can yu explain me again ?</Typography>
                                                    </Box>
                                                    <Typography variant='span' sx={{ minWidth: '20px', width: '20px', height: '20px', backgroundColor: '#EAF4FF', color: '#005DB8', borderRadius: '50%', fontSize: '14px', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px' }}>1</Typography>
                                                </Box>
                                                <Box className="msg_box">
                                                    <img src={msgUser1} />
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#000000', textTransform: 'capitalize', marginBottom: '3px', lineHeight: '1' }}>arlene</Typography>
                                                        <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#555555', textTransform: 'capitalize', lineHeight: '1' }}>can yu explain me again ?</Typography>
                                                    </Box>
                                                    <Typography variant='span' sx={{ minWidth: '20px', width: '20px', height: '20px', backgroundColor: '#EAF4FF', color: '#005DB8', borderRadius: '50%', fontSize: '14px', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px' }}>1</Typography>
                                                </Box>
                                                <Box className="msg_box">
                                                    <img src={msgUser1} />
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#000000', textTransform: 'capitalize', marginBottom: '3px', lineHeight: '1' }}>arlene</Typography>
                                                        <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#555555', textTransform: 'capitalize', lineHeight: '1' }}>can yu explain me again ?</Typography>
                                                    </Box>
                                                    <Typography variant='span' sx={{ minWidth: '20px', width: '20px', height: '20px', backgroundColor: '#EAF4FF', color: '#005DB8', borderRadius: '50%', fontSize: '14px', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px' }}>1</Typography>
                                                </Box>
                                                <Box className="msg_box">
                                                    <img src={msgUser1} />
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#000000', textTransform: 'capitalize', marginBottom: '3px', lineHeight: '1' }}>arlene</Typography>
                                                        <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#555555', textTransform: 'capitalize', lineHeight: '1' }}>can yu explain me again ?</Typography>
                                                    </Box>
                                                    <Typography variant='span' sx={{ minWidth: '20px', width: '20px', height: '20px', backgroundColor: '#EAF4FF', color: '#005DB8', borderRadius: '50%', fontSize: '14px', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px' }}>1</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="chat_right">
                                            <Box className="chat_head">
                                                <img src={msgUser1} />
                                                <Box className="">
                                                    <Typography sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', lineHeight: '1', marginBottom: '3px', textTransform: 'capitalize' }}>arlene</Typography>
                                                    <Typography sx={{ fontSize: '12px', color: '#555555', fontWeight: '500', lineHeight: '1' }}>last seen 1 hr ago</Typography>
                                                </Box>
                                            </Box>
                                            <Box className="messages_box">
                                                <Box className="main_chat left_chat">
                                                    <Typography className='para_chat'>hello how are you ?</Typography>
                                                    <Typography className='time_chat'>1 hour ago</Typography>
                                                </Box>
                                                <Box className="main_chat right_chat">
                                                    <Typography className='para_chat'>hello how are you ?</Typography>
                                                    <Typography className='time_chat'>1 hour ago</Typography>
                                                </Box>
                                                <Box className="main_chat left_chat">
                                                    <Typography className='para_chat'>i have some doubt</Typography>
                                                    <Box className="para_chat_imgs">
                                                        <img src={chat_img} />
                                                        <img src={chat_img} />
                                                    </Box>
                                                    <Typography className='time_chat'>30 min ago</Typography>
                                                </Box>
                                                <Box className="main_chat right_chat">
                                                    <Typography className='para_chat'>ok</Typography>
                                                </Box>
                                            </Box>
                                            <Box className="chat_input">
                                                <Box className='chat_input_inner_wrap'>
                                                    <TextField
                                                        id="outlined-textarea"
                                                        placeholder="Write your message"
                                                    />
                                                    <Box className="upload_send_wrap">
                                                        <Box className="upload_wrap">
                                                            <input type="file" className='upload_file' />
                                                            <img src={attachFile} />
                                                        </Box>
                                                        <Button className="send_btn"><img src={sendAero} /></Button>
                                                    </Box>
                                                </Box>


                                            </Box>
                                        </Box>
                                    </Box>
                                </Box> */}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={4}>
                                <Box className="payment_wrapper">
                                    <Typography sx={{ fontSize: '18px', fontWeight: '500', fontWeight: '500', color: '#000000', marginBottom: '16px', textTransform: 'capitalize' }}>{translation?.PaymentDetails}</Typography>
                                    <Box>
                                    { session?.PaymentDetails &&
                                        <Box className="white-card" sx={{marginBottom: '16px'}}>
                                            <Box className="dflx">
                                                <Box className="left_sect">
                                                    <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: '500', color: '#000', marginBottom: '8px', color: '#000000' }}>{translation?.PrivateCourseOf?.replace('{XXX}', session?.PaymentDetails[0]?.TotalHours)}</Typography>
                                                    { session?.PaymentDetails[0]?.PaymentRefID && 
                                                        <Typography variant='h5' sx={{ fontSize: '18px', fontWeight: '500', lineHeight: '1', color: '#000', marginBottom: '8px', color: '#000000', textTransform: 'capitalize' }}>{translation?.ReferenceIdNo}. - {session?.PaymentDetails[0]?.PaymentRefID}</Typography>
                                                    }
                                                    <Box className="oc_headwrap" sx={{ padding: '0', display: 'flex', gap: '10px', marginBottom: '0 !important' }}>
                                                        <Box className="oc_headleft">
                                                            <List sx={{ padding: '0', display: 'flex', gap: '15px', width: '100%' }}>
                                                                {/* <ListItem><img src={clock}></img> 06:00 pm to 07:00 pm</ListItem>
                                                                <ListItem><img src={calendar}></img> 17 Oct 2023</ListItem> */}
                                                                { session?.PaymentDetails[0]?.PaymentDate && <>
                                                                    <ListItem><img src={clock}></img> {new Date(session?.PaymentDetails[0]?.PaymentDate).toLocaleTimeString()}</ListItem>
                                                                    <ListItem><img src={calendar}></img> {new Date(session?.PaymentDetails[0]?.PaymentDate).toLocaleDateString('en-US')}</ListItem>

                                                                </> }
                                                            </List>
                                                        </Box>
                                                    </Box>
                                                    <Typography variant='h5' sx={{ fontSize: '18px', fontWeight: '500', color: '#000', marginBottom: '8px', color: '#555555' }}>{translation?.SubHeading}</Typography>
                                                </Box>
                                                <Box className="right_sect">
                                                    <Box className="lbl_btn_flx">
                                                        <Typography variant="span" className="lbl_tag lt_yellow">{session?.PaymentDetails[0]?.PaymentStatus}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Typography sx={{ fontSize: '20px', fontWeight: '600', lineHeight: '1', color: '#000000', marginBottom: '16px', }}>
                                                {session?.PaymentDetails[0]?.TotalPayments} {translation?.KuwaitiDinar}
                                            </Typography>
                                            {/* { session?.PaymentDetails[0]?.Invoice === false && 
                                                <Button className="detail_btn">{translation?.PayNow}</Button>
                                            } */}
                                            {/* <Box className="course_list_wrapper">
                                                <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: '500', color: '#000', marginBottom: '16px', color: '#000' }}>courses list</Typography>
                                                <Box className="white-card course_card_wrap" sx={{ marginBottom: '16px' }}>
                                                    <Box className='courses_video'>
                                                       <img src={{courseListImg}} />
                                                    </Box>
                                                    <Box className="courses_text">
                                                        <Typography sx={{ fontSize: '16px', color: '#000000', fontWeight: '500', lineHeight: '1.5', marginBottom: '8px' }}>Session 1 :Obtain the greatest number of your digits , which is divisible by 15,25,40..</Typography>
                                                        <Box className="date_rate_wrapper">
                                                            <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#727272' }}>08:41 pm | 17 Oct 2023</Typography>
                                                            <Box className="rate_review">
                                                                <Stack spacing={2}>
                                                                    <Rating name="half-rating-read" defaultValue={3} precision={0.5} readOnly />
                                                                </Stack>
                                                                <Button className="rate_session">rate this session</Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box> */}
                                        </Box>
                                    }

                                    </Box>
                                    {/* <Box className="viewMore" sx={{ mt: 3, mb: 3, gap: 0.5, display: { xs: 'flex', alignItems: 'center', justifyContent: 'center' } }}>
                                        <button className='viewMoreBtn'>view more (05)</button>
                                    </Box> */}
                                </Box>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={5}>
                                <Assignment />
                                {/* <Box className="mainHeading">
                                    <h4>Assignment</h4>
                                </Box>
                                <Box className="progressNumber-parent">
                                    <Box className="progressNumber">
                                        <Typography variant='div' className='notStarted'><Typography variant='div'>
                                            <img src={clock} />

                                        </Typography>6 hours left</Typography>
                                        <Typography variant='div' className='cyan'> <Typography variant='div'>
                                            <img src={clock} />
                                        </Typography>  100% class attendance</Typography>

                                        <Typography variant='div' className='understood'> <Typography variant='div'>
                                            <img src={clock} />
                                        </Typography> 03/10 Problem solved</Typography>
                                    </Box>
                                    <Box className="assignment_accWrapper">
                                        <div>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1-content"
                                                    id="panel1-header"
                                                >
                                                    <Box className='acc_wrap'>
                                                        <Box className="acc-label"><Typography variant="span" sx={{ fontSize: '20px', fontWeight: 600, color: '005DB8', minWidth: '50px', maxWidth: '50px', minHeight: '50px', maxHeight: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F9FCFF', borderRadius: '50%' }}>01</Typography><Typography variant="p" sx={{ fontSize: '18px', lineHeight: '1.4', color: '#000000', fontWeight: '500' }}>Session 01 Assignment</Typography></Box>
                                                        <Box className="box-level">
                                                            <Typography variant='span' className='cyan'>easy</Typography>
                                                            <Typography variant='span' className="lt-green">solved</Typography>
                                                        </Box>
                                                    </Box>

                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box className="acc_status">
                                                        <Box className="ac_score">
                                                            <Typography variant="span">score</Typography>
                                                            <Typography variant="span">10.0/10</Typography>
                                                        </Box>
                                                        <Box className="ac_score">
                                                            <Typography variant="span">Status</Typography>
                                                            <Typography variant="span" className="c-red">solved</Typography>
                                                        </Box>
                                                        <Box className="ac_score">
                                                            <Typography variant="span">submission</Typography>
                                                            <Typography variant="span" className="c-blue">1 submission</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Button className="darkBlue">
                                                        start solving
                                                    </Button>
                                                    <Box className="Classification">
                                                        <Box className="selectOption">
                                                            <Typography sx={{ mb: 2 }}>
                                                                Not a database
                                                            </Typography>
                                                            <Box className="questioList" >
                                                                <Box className="questionListIn green" sx={{ mb: 1, display: { xs: 'flex', alignItems: 'center', } }}>
                                                                    <Box className="cardIcon">A</Box>
                                                                    <Typography>
                                                                        True
                                                                        <Typography variant='div'>
                                                                            correct answer
                                                                        </Typography>
                                                                    </Typography>
                                                                </Box>
                                                                <Box className="questionListIn" sx={{ mb: 1, display: { xs: 'flex', alignItems: 'center', } }}>
                                                                    <Box className="cardIcon">B</Box>
                                                                    <Typography>
                                                                        False
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2-content"
                                                    id="panel2-header"
                                                >
                                                    <Box className='acc_wrap'>
                                                        <Box className="acc-label"><Typography variant="span" sx={{ fontSize: '20px', fontWeight: 600, color: '005DB8', minWidth: '50px', maxWidth: '50px', minHeight: '50px', maxHeight: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F9FCFF', borderRadius: '50%' }}>02</Typography><Typography variant="p" sx={{ fontSize: '18px', lineHeight: '1.4', color: '#000000', fontWeight: '500' }}>Session 02 Assignment</Typography></Box>
                                                        <Box className="box-level">
                                                            <Typography variant='span' className='cyan'>easy</Typography>
                                                            <Typography variant='span' className="lt-green">solved</Typography>
                                                        </Box>
                                                    </Box>

                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box className="acc_status">
                                                        <Box className="ac_score">
                                                            <Typography variant="span">score</Typography>
                                                            <Typography variant="span">10.0/10</Typography>
                                                        </Box>
                                                        <Box className="ac_score">
                                                            <Typography variant="span">Status</Typography>
                                                            <Typography variant="span" className="c-red">solved</Typography>
                                                        </Box>
                                                        <Box className="ac_score">
                                                            <Typography variant="span">submission</Typography>
                                                            <Typography variant="span" className="c-blue">1 submission</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Button className="darkBlue">
                                                        start solving
                                                    </Button>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel3-content"
                                                    id="panel3-header"
                                                >
                                                    <Box className='acc_wrap'>
                                                        <Box className="acc-label"><Typography variant="span" sx={{ fontSize: '20px', fontWeight: 600, color: '005DB8', minWidth: '50px', maxWidth: '50px', minHeight: '50px', maxHeight: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F9FCFF', borderRadius: '50%' }}>03</Typography><Typography variant="p" sx={{ fontSize: '18px', lineHeight: '1.4', color: '#000000', fontWeight: '500' }}>Session 03 Assignment</Typography></Box>
                                                        <Box className="box-level">
                                                            <Typography variant='span' className='cyan'>easy</Typography>
                                                            <Typography variant='span' className="lt-green">solved</Typography>
                                                        </Box>
                                                    </Box>

                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box className="acc_status">
                                                        <Box className="ac_score">
                                                            <Typography variant="span">score</Typography>
                                                            <Typography variant="span">10.0/10</Typography>
                                                        </Box>
                                                        <Box className="ac_score">
                                                            <Typography variant="span">Status</Typography>
                                                            <Typography variant="span" className="c-red">solved</Typography>
                                                        </Box>
                                                        <Box className="ac_score">
                                                            <Typography variant="span">submission</Typography>
                                                            <Typography variant="span" className="c-blue">1 submission</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Button className="darkBlue">
                                                        start solving
                                                    </Button>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel4-content"
                                                    id="panel4-header"
                                                >
                                                    <Box className='acc_wrap'>
                                                        <Box className="acc-label"><Typography variant="span" sx={{ fontSize: '20px', fontWeight: 600, color: '005DB8', minWidth: '50px', maxWidth: '50px', minHeight: '50px', maxHeight: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F9FCFF', borderRadius: '50%' }}>04</Typography><Typography variant="p" sx={{ fontSize: '18px', lineHeight: '1.4', color: '#000000', fontWeight: '500' }}>Session 04 Assignment</Typography></Box>
                                                        <Box className="box-level">
                                                            <Typography variant='span' className='cyan'>easy</Typography>
                                                            <Typography variant='span' className="lt-green">solved</Typography>
                                                        </Box>
                                                    </Box>

                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box className="acc_status">
                                                        <Box className="ac_score">
                                                            <Typography variant="span">score</Typography>
                                                            <Typography variant="span">10.0/10</Typography>
                                                        </Box>
                                                        <Box className="ac_score">
                                                            <Typography variant="span">Status</Typography>
                                                            <Typography variant="span" className="c-red">solved</Typography>
                                                        </Box>
                                                        <Box className="ac_score">
                                                            <Typography variant="span">submission</Typography>
                                                            <Typography variant="span" className="c-blue">1 submission</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Button className="darkBlue">
                                                        start solving
                                                    </Button>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel5-content"
                                                    id="panel5-header"
                                                >
                                                    <Box className='acc_wrap'>
                                                        <Box className="acc-label"><Typography variant="span" sx={{ fontSize: '20px', fontWeight: 600, color: '005DB8', minWidth: '50px', maxWidth: '50px', minHeight: '50px', maxHeight: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F9FCFF', borderRadius: '50%' }}>05</Typography><Typography variant="p" sx={{ fontSize: '18px', lineHeight: '1.4', color: '#000000', fontWeight: '500' }}>Session 05 Assignment</Typography></Box>
                                                        <Box className="box-level">
                                                            <Typography variant='span' className='cyan'>easy</Typography>
                                                            <Typography variant='span' className="lt-green">solved</Typography>
                                                        </Box>
                                                    </Box>

                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box className="acc_status">
                                                        <Box className="ac_score">
                                                            <Typography variant="span">score</Typography>
                                                            <Typography variant="span">10.0/10</Typography>
                                                        </Box>
                                                        <Box className="ac_score">
                                                            <Typography variant="span">Status</Typography>
                                                            <Typography variant="span" className="c-red">solved</Typography>
                                                        </Box>
                                                        <Box className="ac_score">
                                                            <Typography variant="span">submission</Typography>
                                                            <Typography variant="span" className="c-blue">1 submission</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Button className="darkBlue">
                                                        start solving
                                                    </Button>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </Box>
                                    <Box className="viewMore" sx={{ mt: 3, mb: 3, gap: 0.5, display: { xs: 'flex', alignItems: 'center', justifyContent: 'center' } }}>
                                        <button className='viewMoreBtn'>view more (05)</button>
                                    </Box>
                                </Box> */}
                            </CustomTabPanel>

                        </Box>
                    </Box>

                    <Advertisement />
                </Box>
            </Box>
            <VideoModal isOpen={isVideoModal} title={selectedClass?.SessionName} data={selectedClass} handleClose={handleClose} />
            <ZoomImageContainer />
            <FooterSection />
        </div>
    );
}
export default Overview