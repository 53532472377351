import { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { WebSocketURL } from '../config/apiConfig';
import { getHelperData } from '../actions/getHelperData';
import { getExamBoards } from '../actions/getExamBoardsAction';

let socket; // Declare the socket variable outside the hook

const initializeSocket = (socketUrl, setIsConnected) => {
  if (!socket) {
    console.log("Initializing socket...");
    socket = io(socketUrl, {
      transports: ['websocket'],
      reconnection: true,
      reconnectionAttempts: 3, // Number of attempts
      reconnectionDelay: 1000, // Delay between attempts in milliseconds
    });

    socket.on('connect', () => {
      console.log('Socket Connected');
      setIsConnected(true);
      localStorage.setItem('WebSocketURL', socketUrl);
      if (Cookies.get("loginToken")) {
        const isActive = sessionStorage.getItem('IsActive');
        if (isActive != null || isActive != 'false') {
          const mySocketData = {
            AuthToken: localStorage.getItem('customToken'),
            authorization: JSON.parse(Cookies.get("loginToken"))?.idToken,
            DeviceID: sessionStorage.getItem('deviceId'),
            IsActive: "true"
          }
          socket.emit("LoginSession", JSON.stringify(mySocketData));
        }
      }
    });

    socket.on('disconnect', () => {
      console.log('Socket Disconnected');
      setIsConnected(false)
    });

    socket.on('connect_error', (error) => {
      console.error('Socket Connection Error:', error);
      setIsConnected(false)
    });
  } else {
    setIsConnected(true);
  }
  return socket;
};

const useSocket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const [isConnected, setIsConnected] = useState(false);
  const [isEnvironmentSet, setIsEnvironmentSet] = useState(false);

  const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);
  const boards = useSelector(({ examBoardsReducer }) => examBoardsReducer?.boards);
  const translations = useSelector(({ getTranslationsReducer }) => getTranslationsReducer.translations || []);

  useEffect(() => {
    // Initialize socket
    let currentSocket;
    if (translations?.length > 0) {
      const translation = {
        ...translations[33]?.ApplicationConfiguration
      }
      const socketUrl = translation?.SocketProductionUrlForTutorWeb; //Prod
      // const socketUrl = translation?.SocketDevelopmentUrlForTutorWeb; //Dev

      currentSocket = initializeSocket(socketUrl, setIsConnected);

      // Set up event listeners
      const setupListeners = () => {
        currentSocket.on('CountryEvent', (data) => {
          const response = JSON.parse(data);
          const ID = response?.Data[0]?.ID;
          const payload = {
            RoleID: '3',
            Platform: 'Web',
            AppVersion: '1.0',
          };
          localStorage.setItem('CountryID', ID);
          currentSocket.emit('AppConfiguration', JSON.stringify(payload));
        });

        currentSocket.on('AppConfiguration', (appConfigData) => {
          const appConfigResponse = JSON.parse(appConfigData);
          if (appConfigResponse?.Data?.Environment) {
            localStorage.setItem('Environment', appConfigResponse?.Data?.Environment);
            setIsEnvironmentSet(true);
          }
        });

        currentSocket.on('ClientActiveStatus', (response) => {
          const profile = Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")) : null;
          if (profile) {
            const payload = { UserID: profile?.ID?.toString() };
            currentSocket.emit('ClientResponse', JSON.stringify(payload));
          }
        });

        currentSocket.on('LoginSessionResponseEvent', async (data) => {
          const loginSessionResponse = JSON.parse(data);
      
          const deviceId = sessionStorage.getItem("deviceId");
      
          if (loginSessionResponse?.Data?.DeviceID !== deviceId) {
            sessionStorage.setItem('IsActive', 'false');
            localStorage.setItem('customToken', loginSessionResponse?.Data?.AuthToken);
            navigate('/multi-device');

            const callRequestType = sessionStorage.getItem("CallRequestType")
            const studentRequest = JSON.parse(sessionStorage.getItem('StudentRequest'));
            const tutorResponse = JSON.parse(sessionStorage.getItem('TutorResponse'));
            if (callRequestType) {
                const deviceId = loginSessionResponse.Data.DeviceID;
                const authorization = loginSessionResponse.Data.authorization;


                if (callRequestType === 'QuickCall') {
                  studentRequest.IsDecline = true;
                  studentRequest.requestType = "1";
                  console.log("studentRequestQuickCall" , studentRequest);
                  
                  socket.emit("QCQuestions", JSON.stringify(studentRequest), 'QC');        
                  socket.off('GetOnlineTutorResponseEvent');
                  sessionStorage.removeItem("TutorResponse")
                  sessionStorage.removeItem("StudentRequest")
                  sessionStorage.removeItem("CallRequestType")

                } else if (callRequestType === 'HireTutor') {

                  const payload = {};
                  payload['DeviceID'] = sessionStorage.getItem('deviceId');
                  payload['EndPoint'] = '/TutorRequest';
                  payload['authorization'] = JSON.parse(Cookies.get('loginToken'))?.idToken;;
                  payload['StudentID'] = studentRequest?.StudentID;
                  payload['StudentName'] = studentRequest?.StudentName;
                  payload['StudentClass'] = studentRequest?.StudentClass;
                  payload['StudentClassID'] = studentRequest?.StudentClassID;
                  payload['StudentImage'] = studentRequest?.StudentImage;
                  payload['TopicName'] = studentRequest?.TopicName // send topic name 'English,Maths'
                  payload['IsDecline'] = true;
                  payload['IsAccept'] = false;
                  payload['TutorID'] = studentRequest?.TutorID;
                  payload['langid'] = localStorage.getItem('langId');
                  payload['requestType'] = 1; // student = 1 tutor= 2
                  payload['TutorName'] = studentRequest?.TutorName;
                  payload['TutorImage'] = studentRequest?.TutorImage;
                  payload['TutorRating'] = studentRequest?.TutorRating;
                  payload['TutorPrice'] = studentRequest?.TutorPrice;
                  payload['TutorTotalSesssion'] = studentRequest?.TutorTotalSesssion;
                  payload['isPaymentDone'] = 0; // default = 0  success =2  failed =3 // after payment
                  payload['SessionID'] = tutorResponse?.SessionID; // default send 0 // after payment send session id

                  console.log("payload",payload);
                  socket?.emit('PTModule', JSON.stringify(payload));
                  sessionStorage.removeItem("StudentRequest");
                  sessionStorage.removeItem("CallRequestType");
                  sessionStorage.removeItem("TutorResponse");
                  sessionStorage.removeItem("SessionID");
                } 

              localStorage.removeItem("QCImage");
              sessionStorage.removeItem("TutorResponse");
              sessionStorage.removeItem("CallRequest");
              sessionStorage.removeItem("CallRequestType");
            }

          }
        });
      };

      // Attach listeners if not already set up
      if (!currentSocket.hasListeners('CountryEvent')) {
        setupListeners();
      }
    }


    // Track connection state
    currentSocket?.on('connect', () => setIsConnected(true));
    currentSocket?.on('disconnect', () => setIsConnected(false));

    // Clean up listeners on component unmount
    return () => {
      if (currentSocket) {
        currentSocket.off('CountryEvent');
        currentSocket.off('AppConfiguration');
        currentSocket.off('ClientActiveStatus');
        currentSocket.off('LoginSessionResponseEvent');
      }
    };
  }, [translations]);

  useEffect(() => {
    // Fetch data when the environment is set
    if (isEnvironmentSet && !helperData) {
      dispatch(getHelperData());
    }

    if (isEnvironmentSet && !boards) {
      dispatch(getExamBoards());
    }
  }, [isEnvironmentSet, helperData, boards]);

  return { socket, isConnected: isConnected };
};


export default useSocket;
