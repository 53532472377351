import React, { useState, useEffect, useRef } from "react";
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Avatar } from "@mui/material";
import * as d3 from "d3"; // Import D3.js

import useSocket from "../../custom-hooks/useSocket";

const QuickCallModal = ({ isOpen, isApiCalling, translation, handleClose , handleConnect ,questionID }) => {
  const { socket, isConnected } = useSocket();
  const timerRef = useRef(null);
  const fixedPositions = useRef({}); // Store positions for each tutor
  const svgRef = useRef(null); 
  const [timer, setTimer] = useState(60);
  const [onlineTutors, setOnlineTutors] = useState([]);
  const [showRetry, setShowRetry] = useState(false);

  const tutorObj = {}

  const closed = () => {
    clearInterval(timerRef.current);
    setTimer(60);
    handleClose();
    setShowRetry(false);
    setOnlineTutors([]);
  } 

  const cancelQCRequest = () => {
    setOnlineTutors([]);

    const studentRequest = JSON.parse(sessionStorage.getItem('StudentRequest'));
    if (!socket || !socket.connected) {
      // If socket is not initialized or not connected, do not emit the event
      console.error('Socket is not connected. Unable to cancel request.');
      return;
    }
    studentRequest.IsDecline = true;
    studentRequest.requestType = "1";
    socket.emit("QCQuestions", JSON.stringify(studentRequest), 'QC');
        socket.off('GetOnlineTutorResponseEvent');
        sessionStorage.removeItem("TutorResponse")
        sessionStorage.removeItem("StudentRequest")
        sessionStorage.removeItem("CallRequestType")
  };

  const handleRandom = (min, max) => {
    return `${Math.floor(min + Math.random() * max)}px`;
  }

  const onRetry = () => {
    clearInterval(timerRef.current);
    setTimer(60);
    setShowRetry(false);
    handleConnect(questionID || 0);
    
    // Start a new interval for the countdown
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        const newTimer = prevTimer - 1;

        if (newTimer <= 0) {
          clearInterval(timerRef.current);
          setTimer(0);
          cancelQCRequest();
          setShowRetry(true);
        }
        return newTimer;
      });
    }, 1000);
  };
 
  useEffect(() => {
    if (isOpen) {
      timerRef.current = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer - 1;

          if (newTimer <= 0) {
            clearInterval(timerRef.current);
            setTimer(0);
            cancelQCRequest();
            setShowRetry(true)
            setOnlineTutors([]);
          }
          return newTimer;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [isOpen]);

  useEffect(() => {
      if (isOpen) {
        // if (!socket.hasListeners('GetOnlineTutorResponseEvent')) {
        socket?.on('GetOnlineTutorResponseEvent', (data) => {
          const response = JSON.parse(data);
          console.log("GetOnlineTutorResponseEvent",response.Data);      
          if (Array.isArray(response.Data)) {
            const tutorList = response.Data.map((tutor) => {
              tutorObj[`${tutor.ID}X`] = tutorObj[`${tutor.ID}X`] ? tutorObj[`${tutor.ID}X`] : handleRandom(80, 150)
              tutorObj[`${tutor.ID}Y`] = tutorObj[`${tutor.ID}Y`] ? tutorObj[`${tutor.ID}Y`] : handleRandom(40, 50)
              tutor.x = tutorObj[`${tutor.ID}X`]
              tutor.y = tutorObj[`${tutor.ID}Y`]

              return tutor;
            }
            
          ) 
          console.log("tutorList",tutorList);
          setOnlineTutors(tutorList);
        }  
          
        })
      // }
    }else{
      socket?.off('GetOnlineTutorResponseEvent');
    }

    return () => {
      socket?.off('GetOnlineTutorResponseEvent');
    }
  }, [isOpen])


  useEffect(() => {
    if (!isOpen || !onlineTutors.length) return;

    const svg = d3.select(svgRef.current);
    // svg.selectAll("*").remove(); // Clear previous elements

    const width = 300;
    const height = 300;

    svg.attr("viewBox", `0 0 ${width} ${height}`);

    // Assign fixed positions for each tutor if not already assigned
    const newPositions = {};
    onlineTutors.forEach((tutor) => {
      if (!fixedPositions.current[tutor.ID]) {
        const x = Math.random() * (width - 50) + 25; // Random x within bounds
        const y = Math.random() * (height - 50) + 25; // Random y within bounds
        newPositions[tutor.ID] = { x, y };
      }
    });
    fixedPositions.current = { ...fixedPositions.current, ...newPositions };

    // Bind data to groups
    const tutors = svg
    .selectAll("g")
    .data(onlineTutors)
    .join("g")
    .attr("transform", (d) => {
      const { x, y } = fixedPositions.current[d.ID];
      return `translate(${x}, ${y})`;
    });
  

    // Add ripple effect with a circle
    tutors
      .append("circle")
      .attr("r", 10)
      .attr("fill", "dodgerblue")
      .attr("stroke", "dodgerblue")
      .attr("opacity", 0.5)
      .transition()
      .duration(2000)
      .ease(d3.easeLinear)
      .attr("r", 30)
      .attr("opacity", 0)
      .on("end", function repeat() {
        d3.select(this)
          .attr("r", 10)
          .attr("opacity", 0.5)
          .transition()
          .duration(2000)
          .ease(d3.easeLinear)
          .attr("r", 30)
          .attr("opacity", 0)
          .on("end", repeat);
      });

    // Add profile picture
    tutors
      .append("image")
      .attr("xlink:href", (d) => d.ProfilePictureUrl || "default-avatar.png")
      .attr("width", 40)
      .attr("height", 40)
      .attr("x", -20) // Center the image horizontally
      .attr("y", -20) // Center the image vertically
      .attr("clip-path", "circle(20px at center)");

    // Add tutor name
    tutors
      .append("text")
      .attr("dy", 50) // Position below the image
      .attr("text-anchor", "middle")
      .text((d) => d.UserName || "No Name")
      .attr("fill", "black");

      return() => {
        svg.selectAll("*").remove() // Clear previous elements
      }
      
  }, [onlineTutors, isOpen]);
  
    

  return (
    <Dialog
      open={isOpen}
      keepMounted
      // onClose={closed}
      aria-describedby="alert-dialog-slide-description"
      className="cis-dialog-1"
    >
        {/* <div style={{ position: "relative", width: "100%", height: "100%" }}>
        {onlineTutors.map((tutor) => {
            const randomPosition = {
              top: tutor.x,
              left: tutor.y,
              position: "absolute",
              transform: "translate(-50%, -50%)",
            };
          return (
            <div key={tutor.ID} style={randomPosition}>
              <Avatar alt={tutor.UserName || "No Name"} src={tutor.ProfilePictureUrl} />
              <Typography>{tutor.UserName || "No Name"}</Typography>
            </div>
          );
        })}
        </div> */}
      <DialogTitle>{translation?.ConnectWithFavTeacher}</DialogTitle>
      <DialogContent className="text-center">
      <svg ref={svgRef} width="100%" height="300"></svg>
        <div className="timing-box"
         style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 10, // Ensure it's above the SVG
        }}>
          <div className="timing-box_text-wrap">
            <span>
              {timer} <br /> {translation?.Sec}
            </span>
          </div>
          <div className="ripple_ timing-box-1"></div>
          <div className="ripple_ timing-box-2"></div>
          <div className="ripple_ timing-box-3"></div>
        </div>
      {showRetry ?
        <Typography variant="h5" sx={{color:'red'}}>
          {translation?.TutorNotAvailable}
        </Typography>
          :
        <Typography variant="h5">
          {/* {translation?.RequestPushed} {onlineTutors.length}{" "}
          {translation?.Tutors} */}
        </Typography>
      }
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={closed}
          variant="contained"
          color="error"
          size="large"
          sx={{ px: 5 }}
          disabled={isApiCalling}
        >
          {isApiCalling ? translation?.Sending : translation?.QuickCancel}
        </Button>
        {showRetry && (
          <Button
            onClick={onRetry}
            variant="contained"
            color="primary"
            size="large"
            sx={{ px: 5 }}
            disabled={isApiCalling}
          >
            {translation?.Retry || "Retry"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default QuickCallModal;