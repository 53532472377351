import axios from "axios";
import { API_BASE_URL } from "../config/apiConfig";
import Cookies from 'js-cookie';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(({ resolve, reject }) => {
    if (error) {
      reject(error);
    } else {
      resolve(token);
    }
  });
  failedQueue = [];
};

const setHeaders = (config) => {
  const loginToken = Cookies.get('loginToken') ? JSON.parse(Cookies.get('loginToken')) : null;
  config.headers["authorization"] = loginToken?.idToken;
  config.headers["AuthToken"] = localStorage.getItem("customToken");
  config.headers["deviceid"] = sessionStorage.getItem("deviceId");
  config.headers["langid"] = localStorage.getItem("langId") || 2;
  config.headers["CountryID"] = localStorage.getItem("CountryID") || 113;
  config.headers["RoleID"] = "3";
  config.headers["Environment"] = localStorage.getItem("Environment") || "Prod";

  return config;
};

axios.interceptors.request.use((config) => setHeaders(config));

// Function to log API errors
const logSystemError = async (originalRequest, response) => {
  const payload = {
    UserID: sessionStorage.getItem("userId") || "Unknown",
    RequestBody: JSON.stringify(originalRequest?.data || {}),
    ReponseBody: JSON.stringify(response?.data || {}),
    IsSocket: false,
    ApiEndPoint: originalRequest.url,
    Platform: "Web",
  };

  try {
    await axios.post(
      'https://us-central1-yasa-21022022.cloudfunctions.net/Systemlogs',
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          environment: localStorage.getItem("Environment") || "Prod"
        },
      }
    );
  } catch (error) {
    console.error("Failed to log system error:", error);
  }
};

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, config: originalRequest } = error;

    // Log the error for non-200 status codes
    if (response?.status !== 200) {
      logSystemError(originalRequest, response);
    }

    if (response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["authorization"] = token;
            return axios(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const loginToken = JSON.parse(Cookies.get('loginToken') ? JSON.parse(Cookies.get('loginToken')) : null || "{}");
        if (loginToken?.idToken) {
          const { data } = await axios.get(`${API_BASE_URL}/CustomToken`);
          const payload = {
            idToken: data?.Token,
            expiresIn: loginToken?.expiresIn,
            localId: loginToken?.localId,
            expireTime: Date.now(),
          };
          
          Cookies.set('loginToken', JSON.stringify(payload), { expires: 25 });

          axios.defaults.headers.common["authorization"] = data?.Token;
          processQueue(null, data?.Token);

          // Retry the original request with the new token
          return axios(originalRequest);
        } else {
          throw new Error("Login token not found");
        }
      } catch (err) {
        processQueue(err, null);
        sessionStorage.clear();
        Object.keys(Cookies.get()).forEach(cookieName => {
          Cookies.remove(cookieName);
        });
        window.location.href = "/login";
        return Promise.reject(err); // Navigate to login if token refresh fails
      } finally {
        isRefreshing = false;
      }
    }

    if (response?.status === 401) {
      sessionStorage.clear();
      Object.keys(Cookies.get()).forEach(cookieName => {
        Cookies.remove(cookieName);
      });
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export const axiosGet = (url) => axios.get(url);
export const axiosPost = (url, data) => axios.post(url, data);
export const axiosUpdate = (url, data) => axios.patch(url, data);
