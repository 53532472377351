import React, { useState, useEffect, useRef } from "react";
import { Button } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const HireTutorModal = ({ isOpen, studentRequest, translation,  handleClose }) => {
  const [timer, setTimer] = useState(60);
  const timerRef = useRef(null);

  const closed = () => {
    setTimer(60);
    handleClose();
  }

  useEffect(() => {
    if (isOpen) {
      timerRef.current = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer - 1;

          if (newTimer <= 0) {
            clearInterval(timerRef.current);
            setTimer(55);
            handleClose();
          }

          return newTimer;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      keepMounted
      // onClose={closed}
      aria-describedby="alert-dialog-slide-description"
      className="cis-dialog-1"
    >
      <DialogTitle>
        {translation?.ConnectingWithTutor}{" "}
        <span className="clr-red">{studentRequest?.TutorName}</span>
      </DialogTitle>
      <DialogContent className="text-center">
        <div className="timing-box">
          <div className="timing-box_text-wrap">
            <span>
              {timer} <br /> {translation?.Sec}
            </span>
          </div>
          <div className="ripple_ timing-box-1"></div>
          <div className="ripple_ timing-box-2"></div>
          <div className="ripple_ timing-box-3"></div>
        </div>
        <p className="title-info">{translation?.WhileConnecting}</p>
        <p className="title-info"><b className="moneyFont">{studentRequest?.TutorPrice} {studentRequest?.CurrencyType} </b> / {translation?.HireTutorHour}</p>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={closed}
          variant="contained"
          color="error"
          size="large"
          sx={{ px: 5 }}
        >
          {translation?.QuickCancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HireTutorModal;
